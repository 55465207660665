import React, { useState, FormEvent } from 'react'
import InlineErrorFeedback from '../formui/alerts/InlineErrorFeedback';
import InlineSuccessFeedback from '../formui/alerts/InlineSuccessFeedback';
import { BuildingOffice2Icon, EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline'
import { BugAntIcon, ChatBubbleLeftRightIcon, ComputerDesktopIcon } from '@heroicons/react/24/outline'

import Input from '../formui/input'
import TextArea from '../formui/textarea';

const ContactForm = () => {

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [result, setResult] = useState<{ type?: string; content?: string }>({
    type: '',
    content: ''
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();  
    setLoading(true);  
    setResult({});

    const response = await window
      .fetch(`/api/form`, {
        method: `POST`,
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({name,email,phone,message}),
      })
      .then(res => res.json())
    
    if(response==='OK'){
      setResult({ type: 'success', content: "Thanks for your interest. We will get back to you in 24 hours." });
    }else{
      setResult({ type: 'error', content: response });
    }
    setLoading(false);
};

  return (
    <div className="relative overflow-hidden py-16">
      <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
        <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
          <svg
            className="absolute top-12 left-full translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
          </svg>
          <svg
            className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
          >
            <defs>
              <pattern
                id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
          </svg>
          
        </div>
      </div>
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-prose text-lg">
          <h1>
            <span className="block text-center text-lg font-semibold text-purple-600">Get in touch</span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Contact Us
            </span>
          </h1>

          <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="mx-auto max-w-lg lg:max-w-none">

            <div className="flex gap-x-6 mb-10">
              <div className="flex h-10 w-10 shrink-0 items-center justify-center rounded-lg bg-indigo-600">
                <BuildingOffice2Icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <div>
                <h3 className="font-semibold leading-7 text-gray-900">Our office</h3>
                <div className="text-base mt-2 leading-7 text-gray-600">
                  0/B, Sun Gardens, Edayarpalayam, Tamilnadu, India 641025.
                </div>
                <div className="flex gap-x-4 mt-2">
                  <dt className="flex-none">
                    <span className="sr-only">Telephone</span>
                    <PhoneIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="text-base hover:text-gray-900" href="tel:+1 (555) 234-5678">
                      +91 (962) 996-9994
                    </a>
                  </dd>
                </div>
                <div className="flex gap-x-4 mt-2">
                  <dt className="flex-none">
                    <span className="sr-only">Email</span>
                    <EnvelopeIcon className="h-6 w-5 text-gray-400" aria-hidden="true" />
                  </dt>
                  <dd>
                    <a className="text-base hover:text-gray-900" href="mailto:hello@example.com">
                      support@shieldstack.io
                    </a>
                  </dd>
                </div>
              </div>            
            </div>

            <form onSubmit={handleSubmit} method="POST" className="grid grid-cols-1 gap-y-6">

              {result.content && ( result.type === 'error' 
                                            ? <InlineErrorFeedback message={result.content} closeBtn={false} /> 
                                            : <InlineSuccessFeedback message={result.content} closeBtn={false} /> 
                                            ) 
              }

              <div>
                <label htmlFor="full-name" className="sr-only">
                  Full name
                </label>
                <Input
                  type="text"            
                  onChange={setName}
                  placeholder="Full name"
                  required
                />                
              </div>
              <div>
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <Input
                  type="email"            
                  onChange={setEmail}
                  placeholder="Email address"
                  required
                />  
              </div>
              <div>
                <label htmlFor="phone" className="sr-only">
                  Phone
                </label>
                <Input
                  type="text"            
                  onChange={setPhone}
                  placeholder="Phone"
                  autoComplete="tel"
                  required
                />                 
              </div>
              <div>
                <label htmlFor="message" className="sr-only">
                  Message
                </label>
                <TextArea                          
                  onChange={setMessage}
                  placeholder="Message"
                  rows={4}
                  required
                />                
              </div>
              <div>
                <button
                  type="submit"
                  disabled={loading || !email.length || !name.length || message.length < 30}
                  className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-400 bg-purple-600 py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-purple-700 focus:outline-none"
                >
                  { loading ? 
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg> : null }                  
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>


        </div>        
      </div>
    </div>
  )
}

export default ContactForm