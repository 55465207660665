import * as React from "react"
import { HeadFC } from "gatsby"

import Layout from "../components/layout"
import ContactForm from "../components/contact/contact-form"

const ContactPage = () => {
  return (
    <Layout>
      <ContactForm/>
    </Layout>
  )
}

export default ContactPage

export const Head: HeadFC = () => <title>Contact Support | Shieldstack</title>
